import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Bio from "../components/bio"
import PostCard from "../components/postCard";

import "../style/normalize.css";
import "../style/all.scss";
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
export default function BlogIndex(location) {
  const { site, services, articles } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        services: allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { tags: { in: "services" } } }
        ) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM D, YYYY")
                title
                description
                tags
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 1360) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        articles: allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { tags: { in: "articles" } } }
        ) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM D, YYYY")
                title
                description
                tags
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 1360) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const siteTitle = site.siteMetadata.title;

  const servicePosts = services.edges;
  const articlePosts = articles.edges;
  let postCounter = 0;
  let articleCounter = 0;

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Acasă"
        keywords={[
          `analog`,
          `photography`,
          `clujnapoca`,
          `fotografie`,
          `react`
        ]}
      />
      {/* <Bio /> */}
      {site.siteMetadata.description && (
        <header className="page-head">
          <h2>
            Pentru shop dă <a href="https://shop.grainlab.ro/">click aici!</a>
          </h2>
        </header>
      )}
      {/* <div className='post-feed'>
        {servicePosts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div> */}
      {/* <header className='page-head'>
        <h2 className='page-head-title'>Articole</h2>
        <hr />
      </header> */}
      <div className="post-feed">
        {articlePosts.map(({ node }) => {
          articleCounter++;
          return (
            <PostCard
              key={node.fields.slug}
              count={articleCounter}
              node={node}
              postClass={`post`}
            />
          );
        })}
      </div>
    </Layout>
  );
}
